//@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --margin-left-menu: 260px;
}

.loader-wrapper {
  //position: absolute;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //height: 100%;
  //min-height: 2em;
  //cursor: wait;
  //overflow: hidden;


  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;

  //background-color: #f1f3fa;
  //background-color: #fafbfe;
  background-color: white;
  z-index: 9999;

  @media (max-width:767.98px) {
    left: 0;
  }

  .loader {
    width: 56px;
    height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -28px 0 0 -28px;
  }
}

.content-page-custom {
  padding: 24px 12px 65px !important;
}

@media (min-width: 1367px) {
  .content-page-custom {
    padding: 24px 160px 65px !important;
  }
}

//.profile-box {
//  //border-radius: 5px;
//  //padding: 0;
//  padding-top: 10px;
//  padding-bottom: 10px;
//  margin: 10px 0 0;
//  position: fixed;
//  //left: 0;
//  bottom: 0;
//  background-color: #313a46;
//  //background-color: #7e87f6;
//  //background-color: #6c757d;
//  //background-color: white;
//  vertical-align: bottom;
//  width: 260px;
//
//  .dropdown-toggle {
//    &:after {
//      content: none;
//    }
//  }
//
//  .profile-btn {
//    border-radius: 0;
//    text-align: left;
//    box-shadow: none;
//
//    .avatar {
//      vertical-align: middle;
//      margin-right: 7px;
//    }
//
//    i {
//      padding-top: 2px;
//    }
//  }
//}

//.bg-profile {
//  background-color: #313a46;
//}

button.loading-button {
  span.spinner-border-sm {
    margin-bottom: 2px;
    border-width: 0.15em;
  }
}

div {
  .permission-item {
    display: inline-block;
    border: var(--secondary) 1px solid;
    border-radius: 3px;
    padding: 0.7em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
}

.app-selector {
  //line-height: 70px;
  height: 70px;
  float: left;
  margin-right: 5px;
  //padding-right: 20px;
  //width: 28px;

  i {
    font-size: 28px;
    color: white;
  }
}

.personal-menu {

  a {
    color: #6c757d;

    &.selected {
      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2px;
        content: "";
        background-color: #fd7e14;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.font-mono {
  font-family: "Roboto Mono", monospace !important;
}

@media (max-width: 575.98px) {
  .pt-xs-5 {
    padding-top: 4.5rem !important;
  }

  .mt-xs-5 {
    margin-top: 4.5rem !important;
  }
}